import React, {createContext, useContext, useEffect, useState} from 'react';
import {isEmpty} from 'lodash';
import axios from 'axios';
import LocalStorage from '../utils/localStorage';

const LocaleContext = createContext({});

function LocaleProvider(props) {
  const {children} = props;

  const storedData = LocalStorage.locale || {};
  const [isLoading, setIsLoading] = useState(isEmpty(storedData));
  const [locale, setLocale] = useState(storedData);

  useEffect(() => {
    getLocale();
  }, []);

  async function getLocale() {
    if (isEmpty(locale)) {
      await fetchLocale();
    } else {
      setIsLoading(false);
      setLocale((prevState) => prevState);
    }
  }

  async function fetchLocale() {
    let instance = axios.create();
    instance.interceptors.request.use((config) => {
      delete config.headers.common['x-http-bsid']; // Remove
      delete config.headers.common['x-http-token']; // Remove
      return config;
    });

    try {
      const {data} = await instance.get('https://ipapi.co/json');

      const {country_calling_code, country_code, country_name, languages} =
        data;
      const newLocale = {
        country_calling_code,
        country_code,
        country_name,
        languages,
      };

      LocalStorage.locale = newLocale; // Set localstorage
      setIsLoading(false);
      setLocale(newLocale);
    } catch (e) {
      const newLocale = {
        country_calling_code: '',
        country_code: '',
        country_name: '',
        languages: 'en-GB',
      };

      LocalStorage.locale = newLocale; // Set localstorage
      setIsLoading(false);
      setLocale(newLocale);
    }
  }

  return (
    <LocaleContext.Provider value={{isLoading, locale}}>
      {isLoading ? 'Loading...' : children}
    </LocaleContext.Provider>
  );
}

const useLocale = () => useContext(LocaleContext);

export {LocaleContext, LocaleProvider, useLocale};
