import Loadable from 'components/Loadable';

const setup = {
  exact: true,
  path: '/setup',
  component: Loadable({
    loader: () => import('../views/setup'),
  }),
};

export default setup;
