import React from 'react';
import {withRouter, Redirect} from 'react-router-dom';

import {ERROR_STATUS} from 'configs';
import {isEmpty} from 'lodash/lang';

import Errors from './Errors';

class Index extends React.Component {
  constructor(props) {
    super(props);

    const {code} = this.props.match.params;

    this.state = {
      errCode: isEmpty(code) ? ERROR_STATUS['404'] : ERROR_STATUS[code],
    };
  }

  render() {
    const {errCode} = this.state;

    if (isEmpty(errCode)) return <Redirect to="/not-found" />;

    // We shouldn't have header because of redirecting
    return <Errors errCode={errCode} />;
  }
}

export default withRouter(Index);
