import React from 'react';
import {Redirect} from 'react-router-dom';
import Loadable from 'components/Loadable';
import {ROUTE_ENV_RULES} from 'configs';

const about = {
  path: '/about',
  component: Loadable({
    loader: () => import('../views/about'),
  }),
  routes: [
    {
      exact: true,
      path: '/about',
      component: () => <Redirect to="/about/iaf-certsearch" />,
    },
    {
      exact: true,
      path: '/about/iaf-certsearch',
      component: Loadable({
        loader: () => import('../views/about/iaf-certsearch'),
      }),
    },
    {
      env: ROUTE_ENV_RULES.development,
      exact: true,
      path: '/about/accreditation-bodies',
      component: Loadable({
        loader: () => import('../views/about/accreditation-bodies'),
      }),
    },
    {
      env: ROUTE_ENV_RULES.development,
      exact: true,
      path: '/about/accredited-certifications',
      component: Loadable({
        loader: () => import('../views/about/accredited-certifications'),
      }),
    },
    {
      env: ROUTE_ENV_RULES.development,
      exact: true,
      path: '/about/certification-bodies',
      component: Loadable({
        loader: () => import('../views/about/certification-bodies'),
      }),
    },
    {
      exact: true,
      path: '/about/iaf',
      component: Loadable({
        loader: () => import('../views/about/iaf'),
      }),
    },
    {
      env: ROUTE_ENV_RULES.development,
      exact: true,
      path: '/about/iso-standards',
      component: Loadable({
        loader: () => import('../views/about/iso-standards'),
      }),
    },
    /*{
      exact: true,
      path: '/about/qualitytrade',
      component: Loadable({
        loader: () => import('../views/about/qualitytrade'),
      }),
    },*/
    {
      exact: true,
      path: '/about/iaf-badge',
      component: Loadable({
        loader: () => import('../views/about/iaf-badge'),
      }),
    },
  ],
};

export default about;
