import Loadable from 'components/Loadable';

const searchHistory = {
  path: '/search-history',
  component: Loadable({
    loader: () => import('../views/search-history/index.js'),
  }),
  routes: [
    {
      exact: true,
      path: '/search-history',
      component: Loadable({
        loader: () => import('../views/search-history/list/index.js'),
      }),
    },
    // {
    //   exact: true,
    //   path: '/search-history/recently-viewed',
    //   component: Loadable({
    //     loader: () =>
    //       import('../views/search-history/recently-viewed/index.js'),
    //   }),
    // },
  ],
};

export default searchHistory;
