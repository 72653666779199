import React, {useState, useEffect, useContext, createContext} from 'react';
import {useLocation} from 'react-router';
import {useViewport} from './ViewportContext';

const SidebarContext = createContext();
const SidebarConsumer = SidebarContext.Consumer;

const SidebarProvider = ({children}) => {
  const location = useLocation();
  const viewport = useViewport();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    setIsOpen(false);
  }, [location, viewport]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.removeAttribute('style');
    }
  }, [isOpen]);

  return (
    <SidebarContext.Provider
      value={{
        isOpen,
        toggle,
      }}
    >
      {children}

      {isOpen && (
        <div
          style={{
            top: 0,
            left: 0,
            zIndex: 999,
            position: 'fixed',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0, 0.5)',
          }}
          onClick={() => setIsOpen(false)}
        />
      )}
    </SidebarContext.Provider>
  );
};

const withSidebar = (Component) => (props) =>
  (
    <SidebarConsumer>
      {(sidebar) => <Component sidebar={sidebar} {...props} />}
    </SidebarConsumer>
  );

const useSidebarProvider = () => useContext(SidebarContext);

export {SidebarProvider, SidebarConsumer, withSidebar, useSidebarProvider};
