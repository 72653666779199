import Loadable from 'components/Loadable';

const marks = {
  path: '/marks',
  component: Loadable({
    loader: () => import('../views/marks'),
  }),
  routes: [
    {
      exact: true,
      path: '/marks',
      component: Loadable({
        loader: () => import('../views/marks/details'),
      }),
    },
    {
      exact: true,
      path: '/marks/activate',
      component: Loadable({
        loader: () => import('../views/marks/activate'),
      }),
    },
    {
      exact: true,
      path: '/marks/statistics',
      component: Loadable({
        loader: () => import('../views/marks/statistics'),
      }),
    },
  ],
};

export default marks;
