import Loadable from 'components/Loadable';

const landing = {
  exact: true,
  path: '/',
  component: Loadable({
    loader: () => import('../views/landing'),
  }),
};

export default landing;
