import React, {useState, useContext, createContext, useEffect} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {useAuth} from 'contexts/AuthContext';
import {alertByError} from 'utils/alerts';
import Cookies from 'utils/cookies';
import LocalStorage, {removeItem} from 'utils/localStorage';
import cx from 'classnames';
import {ProgressButton, ProgressToast} from 'components/Progress';
import {getGuidesByVersion, putGuidesByVersion} from 'api/guides';
import routes from 'routes';
import {isEmpty} from 'lodash';
import {useConsent} from './ConsentContext';

import {ENABLE_PROGRESS} from 'configs/enable-features';

function recurseRoutes(values) {
  const {routes = []} = values || {};
  let result = [];

  routes.forEach((r) => {
    const {path, routes} = r;

    result.push(path);
    if (!isEmpty(routes)) {
      result = [...result, ...recurseRoutes({routes: routes})];
    }
  });

  return result;
}
function getPrivateRoutes() {
  let privates = {routes: routes.filter((r) => r.access === 'private')};
  const recurse = [...new Set(recurseRoutes(privates))];
  return recurse.filter(
    (r) => r !== '/' && r !== '/consents' && r !== '/setup' && r !== '/logout'
  );
}

const ProgressContext = createContext({});

function useGetProgress() {
  // This route is on routes/index.js
  // we wont allow an error pages to load the guides if its 404 to avoid the redirect and refresh of data
  const isOnErrorPage = useRouteMatch('/error/:code');

  const {isAuth, account} = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    if (isAuth && !isEmpty(account) && isEmpty(data) && !isOnErrorPage) {
      getData();
    }
  }, [isAuth, account, data, isOnErrorPage]);

  async function getData() {
    if (ENABLE_PROGRESS) {
      setIsLoading(true);
      try {
        const {data} = await getGuidesByVersion();

        setData(data);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        await alertByError(e);
      }
    }
  }

  const totalCount = Object.values(data || {}).length;
  const doneCount = Object.values(data || {}).filter((v) => v).length;
  const isCompleted = totalCount === doneCount;
  const isLast = totalCount - 1 === doneCount;

  return {
    isLoading,
    data,
    doneCount,
    totalCount,
    isLast,
    isCompleted,
    reload: getData,
  };
}

function usePutProgress(callback) {
  const [isUpdating, setIsUpdating] = useState(false);

  async function updateData(data) {
    if (ENABLE_PROGRESS) {
      setIsUpdating(true);
      try {
        await putGuidesByVersion(data);

        setIsUpdating(false);
        if (callback) callback();
      } catch (e) {
        setIsUpdating(false);
        await alertByError(e);
      }
    }
  }

  return {isUpdating, onUpdate: updateData};
}

function ProgressProvider(props) {
  const {children} = props;
  const {consentBanner} = useConsent();
  const {isAuth} = useAuth();
  const [justFinished, setJustFinished] = useState(false);
  const [toastShow, setToastShow] = useState(true);
  const [showProgress, setShowProgress] = useState(
    isAuth && !Cookies.dontShowProgress && !LocalStorage.dontShowProgress
  );
  const privates = useRouteMatch(getPrivateRoutes());

  useEffect(() => {
    // not authenticated and not on private routes
    if (!isAuth) {
      setShowProgress(false);
      if (!!LocalStorage.dontShowProgress) removeItem('dontShowProgress');
    } else {
      setShowProgress(
        isAuth && !Cookies.dontShowProgress && !LocalStorage.dontShowProgress
      );
    }
  }, [isAuth]);

  const {isLoading, data, totalCount, doneCount, isLast, isCompleted, reload} =
    useGetProgress();
  const {onUpdate} = usePutProgress(async () => {
    setToastShow(true);
    await reload();
    if (isLast) {
      setJustFinished(true);
      // sleep for 2 seconds
      await new Promise((r) => setTimeout(r, 4000));
      setToastShow(false);
      setShowProgress(false);
      setJustFinished(false);
    }
  });

  function onSkip(isChecked) {
    if (isChecked) {
      // create exist on show progress
      Cookies.dontShowProgress = 'exist';
    }

    LocalStorage.dontShowProgress = 'exist';
    setShowProgress(false);
  }

  function onShowProgress() {
    if (Cookies.dontShowProgress) Cookies.dontShowProgress = '';
    if (LocalStorage.dontShowProgress) removeItem('dontShowProgress');

    setShowProgress(true);
    setToastShow(true);
  }

  return (
    <ProgressContext.Provider
      value={{
        isLoading,
        doneCount,
        totalCount,
        isCompleted,
        progress: data,
        setToastShow,
        onShowProgress,
        onUpdateProgress: onUpdate,
      }}
    >
      {children}

      {privates && ENABLE_PROGRESS && (
        <div
          className={cx('progress-container', {
            'progress-container__consent-banner': consentBanner,
            'd-none': !showProgress || (isCompleted && !justFinished),
            'd-block': showProgress && !isCompleted,
          })}
        >
          <ProgressToast
            progress={data}
            toastShow={toastShow}
            setToastShow={setToastShow}
            isLoading={isLoading}
            doneCount={doneCount}
            totalCount={totalCount}
            onSkip={onSkip}
          />

          <ProgressButton
            justFinished={justFinished}
            progress={data}
            isLoading={isLoading}
            doneCount={doneCount}
            totalCount={totalCount}
            toastShow={toastShow}
            setToastShow={setToastShow}
          />
        </div>
      )}
    </ProgressContext.Provider>
  );
}

const useProgress = () => useContext(ProgressContext);

export {ProgressContext, ProgressProvider, useProgress};
