import {EXTERNAL_URL} from 'configs';

const downloads = {
  path: '/downloads/:file',
  component: ({match}) => {
    const {file} = match.params;
    let url;

    switch (file) {
      default:
        url = '/not-found';
        break;

      case 'ab_cbs_sample':
        url = EXTERNAL_URL.DATA_TEMPLATE.ab_cbs_excel;
        break;
      case 'ab_cbs':
        url = EXTERNAL_URL.DATA_TEMPLATE.ab_cbs_excel;
        break;
      case 'ab_certs':
        url = EXTERNAL_URL.DATA_TEMPLATE.ab_certs_excel;
        break;
      case 'ab_certs_sample':
        url = EXTERNAL_URL.DATA_TEMPLATE.ab_certs_excel;
        break;
      case 'cb_certs':
        url = EXTERNAL_URL.DATA_TEMPLATE.cb_certs_excel;
        break;
      case 'cb_certs_sample':
        url = EXTERNAL_URL.DATA_TEMPLATE.cb_certs_excel;
        break;
    }

    window.location.replace(url);

    return null;
  },
};

export default downloads;
