import Loadable from 'components/Loadable';

const importSettings = {
  path: '/import-settings',
  component: Loadable({
    loader: () => import('../views/import-settings'),
  }),
  routes: [
    {
      exact: true,
      path: '/import-settings',
      component: Loadable({
        loader: () => import('../views/import-settings/settings'),
      }),
    },
    {
      exact: true,
      path: '/import-settings/mapping',
      component: Loadable({
        loader: () => import('../views/import-settings/mapping'),
      }),
    },
  ],
};

export default importSettings;
