import Loadable from 'components/Loadable';

const companySettings = {
  exact: true,
  path: '/company-settings',
  component: Loadable({
    loader: () => import('../views/company-settings'),
  }),
};

export default companySettings;
