import Loadable from 'components/Loadable';

const enquiry = {
  exact: true,
  path: '/enquiry',
  component: Loadable({
    loader: () => import('../views/enquiry'),
  }),
};

export default enquiry;
