import {useHistory, useLocation} from 'react-router-dom';
import {queryParse, queryStringify} from 'utils/http';
import {extend} from 'lodash';

function useFilter() {
  const {pathname, search} = useLocation();
  const history = useHistory();
  const filters = queryParse(search);
  function updateFilter(values) {
    const updatedFilters = extend(filters, values);
    delete updatedFilters['offset']; //reset pagination
    history.replace({
      pathname,
      search: queryStringify(updatedFilters),
    });
  }
  return {
    filters,
    updateFilter,
  };
}
export default useFilter;
