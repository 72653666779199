import Loadable from 'components/Loadable';

const activate = {
  exact: true,
  path: '/act/:companyId/:verified?',
  component: Loadable({
    loader: () => import('../views/register/activateById'),
  }),
};

export default activate;
