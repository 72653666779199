import Loadable from 'components/Loadable';

const certificationBody = {
  path: '/certification-body/:cbId',
  component: Loadable({
    loader: () => import('../views/certification-body'),
  }),
};

export default certificationBody;
