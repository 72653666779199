import React, {useContext} from 'react';
import {isEmpty} from 'lodash/lang';
import LocalStorage from 'utils/localStorage';
import {getCountries} from 'api/common';
import {COUNTRY_VERSION} from 'configs';

const CommonDataContext = React.createContext({});
const CommonDataConsumer = CommonDataContext.Consumer;

class CommonDataProvider extends React.Component {
  constructor(props) {
    super(props);

    const {countries} = LocalStorage;
    const {data} = countries;

    this.state = {
      countries: data, // countries has to be a object to make as key and value.
    };
  }

  componentDidMount() {
    const {countries, version} = this.state;
    if (isEmpty(countries) || version !== COUNTRY_VERSION) {
      this.fetch();
    }
  }

  fetch = async () => {
    const {data} = await getCountries();
    LocalStorage.countries = {data, version: COUNTRY_VERSION};

    this.setState({
      countries: data,
    });
  };

  render() {
    const {countries} = this.state;

    return (
      <CommonDataContext.Provider
        value={{
          countries: countries,
        }}
      >
        {this.props.children}
      </CommonDataContext.Provider>
    );
  }
}

const useCommonData = () => useContext(CommonDataContext);

export {CommonDataProvider, CommonDataConsumer, useCommonData};
