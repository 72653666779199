import {getActivityProgress} from 'api/import-management';
import {createContext, useContext, useState} from 'react';
import {alertByError} from 'utils/alerts';

const ActivityContext = createContext({});

function ActivityProvider(props) {
  const {children} = props;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  async function getData() {
    setIsLoading(true);
    try {
      const {data} = await getActivityProgress(0);

      setData(data);
    } catch (e) {
      await alertByError(e);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <ActivityContext.Provider
      value={{
        activity: data,
        isLoadingActivity: isLoading,
        getActivity: getData,
      }}
    >
      {children}
    </ActivityContext.Provider>
  );
}

const useActivity = () => useContext(ActivityContext);

export {ActivityContext, ActivityProvider, useActivity};
