import Loadable from 'components/Loadable';

const importManagement = {
  path: '/import-management',
  component: Loadable({
    loader: () => import('../views/import-management'),
  }),
  routes: [
    {
      exact: true,
      path: '/import-management',
      component: Loadable({
        loader: () => import('../views/import-management/data-import'),
      }),
    },
    {
      path: '/import-management/activities',
      component: Loadable({
        loader: () => import('../views/import-management/activities'),
      }),
      routes: [
        {
          exact: true,
          path: '/import-management/activities',
          component: Loadable({
            loader: () => import('../views/import-management/activities/list'),
          }),
        },
        {
          path: '/import-management/activities/:activityId',
          component: Loadable({
            loader: () => import('../views/import-management/activities/view'),
          }),
          routes: [
            {
              exact: true,
              path: '/import-management/activities/:activityId',
              component: Loadable({
                loader: () =>
                  import(
                    '../views/import-management/activities/view/companies'
                  ),
              }),
            },
            {
              exact: true,
              path: '/import-management/activities/:activityId/certifications',
              component: Loadable({
                loader: () =>
                  import(
                    '../views/import-management/activities/view/certifications'
                  ),
              }),
            },
          ],
        },
      ],
    },
  ],
};

export default importManagement;
