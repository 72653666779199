import Loadable from 'components/Loadable';

const help = {
  exact: true,
  path: '/help',
  component: Loadable({
    loader: () => import('../views/help'),
  }),
  routes: [
    {
      exact: true,
      path: '/help',
      component: Loadable({
        loader: () => import('../views/help/details'),
      }),
    },
  ],
};

export default help;
