import React, {useState, createContext, useContext} from 'react';
import {useLocation} from 'react-router-dom';
import {queryParse} from 'utils/http';
import {useLocale} from 'contexts/LocaleContext';
import LocalStorage, {removeItem} from 'utils/localStorage';
import Cookies from 'utils/cookies';
import {useEffect} from 'react';

const CaptchaContext = createContext({});

function CaptchaProvider(props) {
  const {children} = props;
  const {search} = useLocation();
  const query = queryParse(search, {addQueryPrefix: true});

  const {isLoading, locale} = useLocale();
  const {captcha} = query;
  const {country_code} = locale;

  const [captchaCode, setCaptchaCode] = useState();

  useEffect(() => {
    getCode();
  }, [query]);

  function getCode() {
    let code;

    // 1. Check IP
    code = country_code === 'CN' ? 'h' : 'g';

    // 2. Check Localstorage and delete it
    // This is the old way wherein the captcha type is stored on local storage
    // we should handle it through cookies
    if (LocalStorage.captcha) {
      code = LocalStorage.captcha;
      // remove localstorage captcha after setting
      removeItem('captcha');
    }

    // we should handle it on cookies to share the captcha type on both iaf main and dc
    if (Cookies.captchaType) {
      code = Cookies.captchaType;
    }
    // 3. Check URL query
    if (captcha) {
      code = captcha === 'h' ? 'h' : 'g';
    }

    // Save new code into cookies
    Cookies.captchaType = code;

    setCaptchaCode(code);
  }

  return (
    <CaptchaContext.Provider value={{isLoading, captchaCode}}>
      {children}
    </CaptchaContext.Provider>
  );
}

const useCaptchaCode = () => useContext(CaptchaContext);

export {CaptchaContext, CaptchaProvider, useCaptchaCode};
