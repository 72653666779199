import Loadable from 'components/Loadable';

const consents = {
  exact: true,
  path: '/consents',
  component: Loadable({
    loader: () => import('../views/consents'),
  }),
};

export default consents;
