import {isObject, isString, isEmpty} from 'lodash/lang';
import {CDN_URL, IMAGE_WIDTHS} from 'configs';

export const fileToImageUrl = (file, size = IMAGE_WIDTHS.origin) => {
  let parsedFile;

  if (!isObject(file) && isString(file)) {
    try {
      parsedFile = JSON.parse(file);
    } catch (e) {
      parsedFile = {};
    }
  } else {
    parsedFile = file;
  }

  // IMPORTANT: check after parse.
  if (!isObject(parsedFile) && isString(parsedFile)) return null;
  if (isEmpty(parsedFile)) return null;

  const {files_disk_name, files_disk_path, files_thumbnail_name} = parsedFile;

  let url;

  if (IMAGE_WIDTHS[size] && isObject(parsedFile) && !!parsedFile) {
    if (size === IMAGE_WIDTHS.origin) {
      url = `${CDN_URL}${files_disk_path}/${files_disk_name}`;
    } else {
      url = `${CDN_URL}${files_disk_path}/${
        files_thumbnail_name[IMAGE_WIDTHS[size]]
      }`;
    }
  }

  return url;
};

export const dataURItoBlob = (dataURI) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  return new Blob([ab], {type: mimeString});
};

export function returnFileSize(number) {
  // return file size of MB
  return (number / 1048576).toFixed(1);
}
