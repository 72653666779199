import React, {useContext} from 'react';

const PageContext = React.createContext();
const PageConsumer = PageContext.Consumer;

class PageProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      header: false,
      footer: false,
      sidebar: false,
      isOpenLeftSidebar: false,
      isOpenRightSidebar: false,
      isLoading: false,
    };

    this.handleLayout = this.handleLayout.bind(this);
    this.handleLoading = this.handleLoading.bind(this);
    this.handleSidebar = this.handleSidebar.bind(this);
  }

  handleLayout({header, footer, sidebar}) {
    this.setState({
      header: header || false,
      footer: footer || false,
      sidebar: sidebar || false,
    });
  }

  handleLoading(isLoading = false) {
    this.setState({
      isLoading: isLoading,
    });
  }

  handleSidebar(target, open = false) {
    let obj = {};

    switch (target) {
      default:
      case 'both':
        obj = {isOpenLeftSidebar: open, isOpenRightSidebar: open};
        break;
      case 'left':
        obj = {isOpenLeftSidebar: open};
        break;
      case 'right':
        obj = {isOpenRightSidebar: open};
        break;
    }

    this.setState(obj);
  }

  render() {
    const {
      header,
      footer,
      sidebar,
      isOpenLeftSidebar,
      isOpenRightSidebar,
      isLoading,
    } = this.state;

    return (
      <PageContext.Provider
        value={{
          header: header,
          footer: footer,
          sidebar: sidebar,
          isOpenLeftSidebar: isOpenLeftSidebar,
          isOpenRightSidebar: isOpenRightSidebar,
          isLoading: isLoading,

          setLayout: this.handleLayout,
          setLoading: this.handleLoading,
          setSidebar: this.handleSidebar,
        }}
      >
        {this.props.children}
      </PageContext.Provider>
    );
  }
}

const withPage = (Component) => {
  return class extends React.Component {
    render() {
      return (
        <PageConsumer>
          {(page) => <Component page={page} {...this.props} />}
        </PageConsumer>
      );
    }
  };
};

const usePage = () => useContext(PageContext);

export {PageProvider, PageConsumer, withPage, usePage};
