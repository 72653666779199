import Loadable from 'components/Loadable';

const contact = {
  exact: true,
  path: '/contact',
  component: Loadable({
    loader: () => import('../views/contact'),
  }),
};

export default contact;
