import {keys} from 'lodash/object';
import {isEmpty, isObject, isNull} from 'lodash/lang';
import Swal from 'sweetalert2';
import {getErrorMessage} from 'utils/errors';

/*
{
  animation: false,
  showConfirmButton: true,
  confirmButtonColor: '#023b89',
  confirmButtonText: 'OK',
  showCancelButton: false,
  cancelButtonColor: '#6c757d',
  cancelButtonText: 'Cancel',
  type: '',
  title: '',
  text: '',
}
 */

const optionBase = {
  animation: false,
  confirmButtonColor: '#023b89',
  confirmButtonText: 'OK',
  cancelButtonColor: '#6c757d',
  cancelButtonText: 'Cancel',
};

const swalBase = Swal.mixin(optionBase);

export const alertSuccess = (options = {}) => {
  return swalBase({
    allowOutsideClick: false,
    confirmButtonColor: '#023b89',
    confirmButtonText: 'OK',
    type: 'success',
    title: 'Success',
    ...options,
  });
};

export const alertError = (options = {}) => {
  return swalBase({
    allowOutsideClick: false,
    confirmButtonColor: '#6c757d',
    confirmButtonText: 'Close',
    type: 'error',
    title: 'Oops...',
    text: 'Something went wrong...',
    ...options,
  });
};

export const alertBase = (options = {}) => {
  if (!isObject(options)) {
    options = {};
  }

  if (isEmpty(options) || isEmpty(options.type)) {
    if (!isNull(options.type)) {
      options.type = 'success';
    }
  }

  if (options.type === 'error') {
    return alertError(options);
  } else if (options.type === 'success') {
    return alertSuccess(options);
  } else {
    return swalBase({
      allowOutsideClick: false,
      confirmButtonColor: '#023b89',
      confirmButtonText: 'OK',
      ...options,
    });
  }
};

export const alertConfirm = (options = {}) => {
  return swalBase({
    showConfirmButton: true,
    showCancelButton: true,
    type: 'warning',
    title: 'Confirm',
    text: 'Are you sure you want',
    ...options,
  });
};

export const alertByError = (error, options = {}) => {
  const {code, message} = error;

  if (message) {
    // default error
    if (typeof message === 'string') {
      options.text = getErrorMessage(code, message);
    }
    // validation error
    else if (typeof message === 'object') {
      let html = [];

      keys(message).forEach((key) => {
        html.push(getErrorMessage(key, message[key]));
      });

      /*values(message).forEach((item) => {
        html = html.concat(item);
      });*/

      options.html = html.join('<br />');
    }
  }

  return swalBase({
    type: 'error',
    showConfirmButton: false,
    showCancelButton: true,
    cancelButtonColor: '#6c757d',
    cancelButtonText: 'Close',
    title: 'Oops...',
    text: 'Something went wrong...',
    ...options,
  });
};

export const alertByInsuffCredits = ({
  cancelLink = '/certifications-list',
  okLink = '/membership/plans?redirect=certifications-list',
}) => {
  const swalOptions = {
    showCloseButton: true,
    showCancelButton: false,
    showConfirmButton: false,
    title: 'You have insufficient credits',
  };

  const html = `<div style="font-size: 16px">You are trying to access data that exceeds your available credits. To continue verifying certifications, consider upgrading your plan.</div>`;
  const footer = `<a class="d-block d-md-inline-block btn btn-outline-primary mr-0 mr-md-2 mb-2 mb-md-0 rounded" href=${cancelLink}>Cancel</a> <a class="d-block d-md-inline-block btn btn-primary rounded" href=${okLink}>Upgrade Plan</a>`;

  alertConfirm({
    ...swalOptions,
    html,
    footer,
  });
};
