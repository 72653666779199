import Loadable from 'components/Loadable';
import {ENABLE_USERS} from 'configs/enable-features';

const users = {
  exact: true,
  path: '/users/:userId?/:type?',
  component:
    ENABLE_USERS &&
    Loadable({
      loader: () => import('../views/users'),
    }),
};

export default users;
