import React from 'react';
import {Redirect} from 'react-router';
import Loadable from 'components/Loadable';

const ceProfile = {
  path: '/ce-profile',
  component: Loadable({
    loader: () => import('../views/ce-profile'),
  }),
  routes: [
    {
      exact: true,
      path: '/ce-profile',
      component: () => <Redirect to="/ce-profile/profile" />,
    },
    {
      exact: true,
      path: '/ce-profile/profile',
      component: Loadable({
        loader: () => import('../views/ce-profile/profile'),
      }),
    },
  ],
};

export default ceProfile;
