import Loadable from 'components/Loadable';

const certification = {
  exact: true,
  path: '/certification/:mdbCertId',
  component: Loadable({
    loader: () => import('../views/certification'),
  }),
};

export default certification;
