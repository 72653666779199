import React, {useContext} from 'react';
import equal from 'deep-equal';
import {keys} from 'lodash/object';
import Measure from 'react-measure';
import {SIZES, VIEWPORT_WIDTHS} from 'configs';

const ViewportContext = React.createContext();
const ViewportConsumer = ViewportContext.Consumer;

const getSize = (width) => {
  let size;

  for (let i of keys(SIZES)) {
    if (width >= VIEWPORT_WIDTHS[i]) {
      size = i;
    }
  }

  return size;
};

class ViewportProvider extends React.Component {
  state = {
    size: getSize(0, 0),
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !equal(nextState.size, this.state.size);
  }

  setViewport({width, height}) {
    // Set size.
    this.setState({
      size: getSize(width, height),
    });
  }

  render() {
    const {size} = this.state;

    return (
      <ViewportContext.Provider
        value={{
          size: size,
        }}
      >
        <Measure
          bounds
          onResize={(contentRect) => {
            // Set dimensions.
            this.setViewport({
              width: contentRect.bounds.width,
              height: contentRect.bounds.height,
            });
          }}
        >
          {({measureRef}) => <div ref={measureRef}>{this.props.children}</div>}
        </Measure>
      </ViewportContext.Provider>
    );
  }
}

const useViewport = () => useContext(ViewportContext);

const withViewport = (Component) => {
  return class extends React.Component {
    render() {
      return (
        <ViewportConsumer>
          {(viewport) => <Component viewport={viewport} {...this.props} />}
        </ViewportConsumer>
      );
    }
  };
};

export {ViewportProvider, ViewportConsumer, withViewport, useViewport};
