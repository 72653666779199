import Loadable from 'components/Loadable';

const enquiries = {
  path: '/enquiries',
  component: Loadable({
    loader: () => import('../views/enquiries'),
  }),
  routes: [
    {
      exact: true,
      path: '/enquiries',
      component: Loadable({
        loader: () => import('../views/enquiries/list'),
      }),
    },

    {
      path: '/enquiries/:enquiryId',
      component: Loadable({
        loader: () => import('../views/enquiries/view'),
      }),
    },
  ],
};

export default enquiries;
