import Loadable from 'components/Loadable';

const login = {
  exact: true,
  path: '/login/:companyId?',
  component: Loadable({
    loader: () => import('../views/login'),
  }),
};

export default login;
