import React from 'react';
import {Redirect} from 'react-router';
import Loadable from 'components/Loadable';

const settings = {
  path: '/settings',
  component: Loadable({
    loader: () => import('../views/settings'),
  }),
  routes: [
    {
      exact: true,
      path: '/settings',
      component: () => <Redirect to="/settings/user-profile" />,
    },
    {
      exact: true,
      path: '/settings/user-profile',
      component: Loadable({
        loader: () => import('../views/settings/user-profile'),
      }),
    },
    {
      exact: true,
      path: '/settings/security',
      component: Loadable({
        loader: () => import('../views/settings/security'),
      }),
    },

    {
      path: '/settings/notification',
      component: Loadable({
        loader: () => import('../views/settings/notification'),
      }),
    },

    {
      exact: true,
      path: '/settings/account',
      component: Loadable({
        loader: () => import('../views/settings/account'),
      }),
    },
  ],
};

export default settings;
