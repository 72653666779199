import Loadable from 'components/Loadable';

const logout = {
  exact: true,
  path: '/logout',
  component: Loadable({
    loader: () => import('../views/logout'),
  }),
};

export default logout;
