import Loadable from 'components/Loadable';

const redirect = {
  path: '/r',
  component: Loadable({
    loader: () => import('../views/redirects'),
  }),
  routes: [
    // Redirect url through the badge link
    {
      exact: true,
      path: '/r/b/:badgeId',
      component: Loadable({
        loader: () => import('../views/redirects/badge'),
      }),
    },
  ],
};

export default redirect;
