import Loadable from 'components/Loadable';

const accreditationBody = {
  path: '/accreditation-body/:abId',
  component: Loadable({
    loader: () => import('../views/accreditation-body'),
  }),
};

export default accreditationBody;
