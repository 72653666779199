import Loadable from 'components/Loadable';

const lettersOfSupport = {
  exact: true,
  path: '/letters-of-support',
  component: Loadable({
    loader: () => import('../views/letters-of-support'),
  }),
};

export default lettersOfSupport;
