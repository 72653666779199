import {encrypt, decrypt} from './encrypt';
import {isEmpty, isNumber, isNil, isBoolean} from 'lodash/lang';
import {get} from 'lodash/object';
import cuid from 'cuid';

import {IS_ENV_DEV, COOKIE_PREFIX, DOMAIN} from 'configs';

const getCookie = (cname) => {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);

  let ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];

    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }

    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return '';
};

const setCookie = (cname, cvalue, exdays, maxAge, domain) => {
  const cookie = `${cname}=${cvalue};`;
  let expires = '';
  const path = 'path=/;';

  let _domain = '';
  if (!!domain) _domain = `domain=${domain};`;

  if (typeof cvalue === 'string' && cvalue === '') {
    // https://stackoverflow.com/questions/5688491/unable-to-delete-cookie-from-javascript
    document.cookie = `${cookie}expires=Thu, 01 Jan 1970 00:00:00 UTC;${_domain}${path}`;
    return;
  }

  if (!isEmpty(exdays) || isNumber(exdays)) {
    let d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000); // Day
    expires = `expires=${d.toUTCString()};`;
  }

  if (!isEmpty(maxAge) || isNumber(maxAge)) {
    // 60*60*24*365 or 31536000 for a year
    // 60*60*24 for a day
    // 60*60 for a hour
    // 60 for a minute
    expires = `max-age=${maxAge};`;
  }

  document.cookie = `${cookie}${expires}${_domain}${path}`;
};

const _getCookie = (name) => {
  const value = getCookie(`${COOKIE_PREFIX}_${name}`);
  const decrypted = decrypt(value);

  // this if statement will true if the secret cookies is changed
  if (!!value && isNil(decrypted)) {
    const domain = IS_ENV_DEV ? '' : DOMAIN;

    // This will delete the old cookies if the domain is default
    setCookie(`${COOKIE_PREFIX}_${name}`, '', null, null, '');
    // this will delete the new cookies if the domain is specified already
    setCookie(`${COOKIE_PREFIX}_${name}`, '', null, null, domain);
    return undefined;
  }

  return decrypted;
};

const _setCookie = (name, value, exdays, maxAge) => {
  if (!isEmpty(value) || isBoolean(value)) {
    value = encrypt(value);
  } else {
    // Delete cookie if it is exist.
    value = '';
    exdays = -1;
  }

  const domain = IS_ENV_DEV ? '' : DOMAIN;

  setCookie(`${COOKIE_PREFIX}_${name}`, value, exdays, maxAge, domain);
};

const Cookies = {
  get session() {
    return _getCookie('session');
  },
  set session(value) {
    const exdays = get(value, 'remember', 7);

    _setCookie('session', value, exdays, '');
  },
  get bsid() {
    let id = _getCookie('bsid');

    if (isEmpty(id)) {
      id = cuid(); // create new id
      _setCookie('bsid', id, 365);
    }

    return id;
  },
  get captchaToken() {
    return _getCookie('captcode');
  },
  set captchaToken(value) {
    _setCookie('captcode', value, 1, '');
  },
  get captchaType() {
    return _getCookie('capttype');
  },
  set captchaType(value) {
    _setCookie('capttype', value, 1, '');
  },
  get consent() {
    return _getCookie('consent');
  },
  set consent(value) {
    // changes consent after 30 days from 365 days
    _setCookie('consent', value, 30, '');
  },
  get countryCode() {
    return _getCookie('countryCode');
  },
  set countryCode(value) {
    _setCookie('countryCode', value, 365, '');
  },

  set emailSession(value) {
    _setCookie('e-session', value, 7, '');
  },
  get emailSession() {
    return _getCookie('e-session');
  },

  set dontShowProgress(value) {
    _setCookie('dontShowProgress', value, 30, '');
  },
  get dontShowProgress() {
    return _getCookie('dontShowProgress');
  },
};

export default Cookies;
