import Loadable from 'components/Loadable';

const reset = {
  exact: true,
  path: '/reset/:token',
  component: Loadable({
    loader: () => import('../views/reset'),
  }),
};

export default reset;
