import {getAvailableLimit} from 'api/membership';
import {alertByError} from 'utils/alerts';
import {useAuth} from './AuthContext';
import {createContext, useContext, useEffect, useState} from 'react';
import {SUBSCRIPTION_PLANS_TYPE} from 'configs/subscription';
import {useSubscription} from './SubscriptionContext';
import {Button, Modal, ModalBody, ModalFooter} from 'reactstrap';
import urljoin from 'url-join';
import {CDN_IMAGE_URL} from 'configs';
import LocalStorage from 'utils/localStorage';
import {useHistory} from 'react-router';

const LimitsContext = createContext({});
const storedData = LocalStorage.showCreditsWarning;

function LimitWarningModal(props) {
  const {toggle, isOpen} = props;
  const history = useHistory();

  function handleRedirect(path) {
    toggle();
    history.push(path);
  }

  return (
    <Modal isOpen={isOpen && storedData === '1'} centered>
      <div className="position-absolute" style={{right: 20, top: 10}}>
        <h4
          className="text-secondary"
          style={{cursor: 'pointer'}}
          onClick={toggle}
        >
          &times;
        </h4>
      </div>
      <div>
        <img
          alt="Verification Credits Running Low!"
          src={urljoin(CDN_IMAGE_URL, 'vu/low-credits.jpg')}
          width="100%"
          height="100%"
        />
      </div>
      <ModalBody className="d-flex flex-column justify-content-center align-items-center p-4">
        <h4 className="font-weight-bold">Verification Credits Running Low!</h4>
        <p className="mb-0">
          Your certificate verification credits are running low. Upgrade your
          plan to ensure you can verify the certifications your organisation
          needs and stay updated on any changes to certification included in
          your watchlist, including suspensions, withdrawals, or expirations.
        </p>
      </ModalBody>
      <ModalFooter>
        <div className="flex-grow-1" onClick={toggle}>
          <Button color="white" className="text-secondary">
            Skip
          </Button>
        </div>
        <Button
          color="outline-primary"
          onClick={() => handleRedirect('/watch-list')}
        >
          Manage Watchlist
        </Button>
        <Button
          color="primary"
          onClick={() => handleRedirect('/membership/plans')}
        >
          Upgrade Now
        </Button>
      </ModalFooter>
    </Modal>
  );
}

function LimitsProvider(props) {
  const {children} = props;

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const {isAuth, isFetchingSession} = useAuth();
  const {subscriptionPlan, isSubscribed} = useSubscription();
  const isPremium = subscriptionPlan === SUBSCRIPTION_PLANS_TYPE.premium;
  const hasLimit = subscriptionPlan !== SUBSCRIPTION_PLANS_TYPE.premium;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isAuth && hasLimit) {
      getLimit();
    }
  }, [isAuth]);

  async function getLimit() {
    setIsLoading(true);
    try {
      const data = await getAvailableLimit();

      setData({...data});
      setIsLoading(false);
    } catch (e) {
      alertByError(e);
    }
  }

  const {
    available_limit_left: limit,
    total_limit: totalLimit,
    expiring_watchlist_items: expiringWatchlist,
  } = data;

  const limitReached = limit === 0;

  function toggle() {
    LocalStorage.showCreditsWarning = '0';
    setIsOpen((prev) => !prev);
  }

  useEffect(() => {
    if (expiringWatchlist > limit && !isPremium && !isFetchingSession) {
      // if watchlist > limit, show warning
      setIsOpen((prev) => !prev);
    }
  }, [expiringWatchlist, limit]);

  return (
    <LimitsContext.Provider
      value={{limit, isLoading, getLimit, limitReached, totalLimit}}
    >
      {isAuth && isSubscribed && (
        <LimitWarningModal
          isOpen={isOpen && storedData === '1'}
          toggle={toggle}
        />
      )}
      {children}
    </LimitsContext.Provider>
  );
}

const useLimits = () => useContext(LimitsContext);

export {LimitsContext, LimitsProvider, useLimits};
