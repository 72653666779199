import Loadable from 'components/Loadable';

const certifiedEntity = {
  exact: true,
  path: '/certified-entity/:companyId',
  component: Loadable({
    loader: () => import('../views/certified-entity'),
  }),
};

export default certifiedEntity;
