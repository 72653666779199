import Loadable from 'components/Loadable';

const coming = {
  exact: true,
  path: '/coming',
  component: Loadable({
    loader: () => import('../views/coming'),
  }),
};

export default coming;
