import React from 'react';
import equal from 'deep-equal';
import {assign} from 'lodash/object';

import LocalStorage from 'utils/localStorage';

const MailingContext = React.createContext();
const MailingConsumer = MailingContext.Consumer;

class MailingProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activations: LocalStorage.activations,
      invitations: LocalStorage.invitations,
    };

    this.setActivation = this.setActivation.bind(this);
    this.setInvitation = this.setInvitation.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const {activations, invitations} = this.state;

    if (!equal(prevState.activations, activations)) {
      LocalStorage.activations = activations;
    }

    if (!equal(prevState.invitations, invitations)) {
      LocalStorage.invitations = invitations;
    }
  }

  setActivation(id) {
    this.setState((prevState) => {
      return {
        activations: assign({}, prevState.activations, {
          [id]: {
            timestamp: new Date().getTime(),
            to: id,
          },
        }),
      };
    });
  }

  setInvitation(id) {
    this.setState((prevState) => {
      return {
        invitations: assign({}, prevState.invitations, {
          [id]: {
            timestamp: new Date().getTime(),
            to: id,
          },
        }),
      };
    });
  }

  render() {
    const {activations, invitations} = this.state;

    return (
      <MailingContext.Provider
        value={{
          activations: activations,
          invitations: invitations,
          setActivation: this.setActivation,
          setInvitation: this.setInvitation,
        }}
      >
        {this.props.children}
      </MailingContext.Provider>
    );
  }
}

const withMailing = (Component) => {
  return class extends React.Component {
    render() {
      return (
        <MailingConsumer>
          {(mailing) => <Component mailing={mailing} {...this.props} />}
        </MailingConsumer>
      );
    }
  };
};

export {MailingProvider, MailingConsumer, withMailing};
