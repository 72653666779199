import React from 'react';
import Loadable from 'components/Loadable';
import {DOCUMENTATION_LANDING_URL} from 'configs';
import {Replace} from 'routes';
import {ENABLE_SUBSCRIPTION} from 'configs/enable-features';

const dashboard = {
  path: '/dashboard',
  component: Loadable({
    loader: () => import('../views/dashboard'),
  }),
  routes: [
    {
      exact: true,
      path: '/dashboard',
      component: Loadable({
        loader: () => {
          return ENABLE_SUBSCRIPTION
            ? import('../views/dashboard/v2')
            : import('../views/dashboard/v1');
        },
      }),
    },
    {
      path: '/dashboard/user-guide',
      component: () => <Replace to={DOCUMENTATION_LANDING_URL} />,
    },
  ],
};

export default dashboard;
