import React, {createContext, useContext} from 'react';
import {get} from 'lodash';
import {useAuth} from './AuthContext';
import {SUBSCRIPTION_STATUS} from 'configs/subscription';

const SubscriptionContext = createContext();

function SubscriptionProvider(props) {
  const {children} = props;
  const {account} = useAuth();

  const isSubscribed =
    get(account, 'company.subscription.stripe_status') ===
    SUBSCRIPTION_STATUS.active;

  const isSubscriptionCanceled =
    get(account, 'company.subscription.stripe_status') ===
    SUBSCRIPTION_STATUS.canceled;

  const subscriptionPlan = get(
    account,
    'company.subscription.subscription_plans_type'
  );

  const subscriptionPlanMeta = get(
    account,
    'company.subscription.subscription_plans_meta'
  );

  return (
    <SubscriptionContext.Provider
      value={{
        isSubscribed,
        isSubscriptionCanceled,
        subscriptionPlan,
        subscriptionPlanMeta,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
}

const useSubscription = () => useContext(SubscriptionContext);

export {SubscriptionContext, SubscriptionProvider, useSubscription};
