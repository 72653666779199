import {useState} from 'react';
import {alertByError} from 'utils/alerts';

import {getMembershipBillingManage} from 'api/membership';

function useManageSubscription(endpoint = 'membership') {
  const [isLoading, setIsLoading] = useState(false);

  const payload = {
    url: `${window.location.origin}/${endpoint}`,
  };

  async function onManage() {
    setIsLoading(true);
    try {
      const {data} = await getMembershipBillingManage(payload);
      window.location.replace(data);
    } catch (error) {
      alertByError(error);
    } finally {
      setIsLoading(false);
    }
  }

  return {
    isLoading,
    onManage,
  };
}

export default useManageSubscription;
