import Loadable from 'components/Loadable';

const promotions = {
  path: '/validate-certificates',
  component: Loadable({
    loader: () => import('../views/promotions/validate-certificates'),
  }),
  routes: [
    {
      exact: true,
      path: '/validate-certificates',
      component: Loadable({
        loader: () =>
          import('../views/promotions/validate-certificates/landing'),
      }),
    },
    {
      path: [
        '/validate-certificates/verify/:accountId',
        '/validate-certificates/verified/:accountId',
      ],
      component: Loadable({
        loader: () =>
          import('../views/promotions/validate-certificates/verify'),
      }),
    },
  ],
};

export default promotions;
