import Loadable from 'components/Loadable';
import {ENABLE_REGISTRATION} from 'configs/enable-features';

const register = {
  path: '/register',
  component: Loadable({
    loader: () => import('../views/register'),
  }),
  routes: [
    {
      exact: true,
      path: '/register',
      component: Loadable({
        loader: () => import('../views/register/type'),
      }),
    },
    {
      exact: true,
      path: '/register/user/(verified)?',
      component:
        ENABLE_REGISTRATION &&
        Loadable({
          loader: () => import('../views/register/user'),
        }),
    },
    {
      exact: true,
      path: '/register/accreditation-body',
      component: Loadable({
        loader: () => import('../views/register/accreditation-body'),
      }),
    },
    {
      exact: true,
      path: '/register/certification-body',
      component: Loadable({
        loader: () => import('../views/register/certification-body'),
      }),
    },
    {
      exact: true,
      path: '/register/certified-entity/:token?',
      component: Loadable({
        loader: () => import('../views/register/certified-entity'),
      }),
    },
  ],
};

export default register;
