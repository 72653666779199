import Loadable from 'components/Loadable';

const forgot = {
  path: '/forgot',
  component: Loadable({
    loader: () => import('../views/forgot'),
  }),
  routes: [
    {
      exact: true,
      path: '/forgot/password',
      component: Loadable({
        loader: () => import('../views/forgot/password'),
      }),
    },
  ],
};

export default forgot;
