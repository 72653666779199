import {createContext, useContext, useState} from 'react';
import {getGlobalListingCount} from 'api/company-list';
import {alertByError} from 'utils/alerts';

const GlobalCountContext = createContext({});

function GlobalCountProvider(props) {
  const {children} = props;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(false);

  async function getGlobalCounts() {
    setIsLoading(true);
    try {
      const {data} = await getGlobalListingCount();

      setData(data);
    } catch (e) {
      alertByError(e);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <GlobalCountContext.Provider
      value={{
        getGlobalCounts,
        isGlobalCountsLoading: isLoading,
        globalCounts: data,
      }}
    >
      {children}
    </GlobalCountContext.Provider>
  );
}

const useGlobalCounts = () => useContext(GlobalCountContext);

export {GlobalCountContext, GlobalCountProvider, useGlobalCounts};
